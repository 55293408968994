import React from "react"

import withWindow from "../helpers/withWindow"
import { Link } from "gatsby"
import { InfoCard, Selectable, CopyButton } from "../components/inform"

const NotFoundPage = () => {
  const email = "help@podplease.com"

  return (
    <>
      <InfoCard title="Page Not Found" heading="Whale, what do you know?">
        <p>
          Some kind of error happened - we don&apos;t know what it was. Could
          you email us please?
        </p>
        <Selectable value={email} />
        <CopyButton context={email} title="Copy our email address" />
        <Link className="button" data-variant="ghost" to="/">
          Go to the home page
        </Link>
      </InfoCard>
    </>
  )
}

export default withWindow(NotFoundPage)
